import React, { Component } from "react";
import { Button, Form, Table, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import '../Advertisements/Advertisement.css';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
import moment from 'moment-timezone';
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class PublishedOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            offers: [],
            topicData: [],
            redirect: false,
            isLoading: true
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        //  console.log("offers", this.state.offers)
        this.getAllPublishedOffers();
        this.getAllArticleTopicUrls();
    }

    handleChange = (e) => {
        const id = e.target.id;
        const elementsIndex = this.state.offers.findIndex(element => element.id == id)
        let newArray = [...this.state.offers]
        newArray[elementsIndex] = { ...newArray[elementsIndex], value: !newArray[elementsIndex].value }
        this.setState({ offers: newArray });
        // console.log("hi", newArray)
    }
    getAllPublishedOffers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPublishedOffer', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        offers: response.data.offer,
                        isLoading: false
                    })
                    //     console.log("getSavedPosts", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAllArticleTopicUrls = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllArticleTopicUrls', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    self.setState({
                        topicData: response.data.getAllArticleTopicUrls,
                        isLoading: false
                    })
                    //     console.log("getSavedPosts", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { isLoading, offers, topicData } = this.state
        const b = offers.map((i, idx) => {
            var a = new Date(i.createdAt)
            var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
            return updated;
        })
        const endAdvDate = offers.map((i, idx) => {
            var end = new Date(i.endDate)
            var updatedEndDate = end.getDate() + "-" + parseInt(end.getMonth() + 1) + "-" + end.getFullYear();
            return updatedEndDate;
        })
        // if (isLoading) {
        //     return <div className="App"></div>;
        // }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >

                    <div >
                        <div> <Link to="/offers/createoffer"><Button variant="default" className="createadv-btn">+ &nbsp;Create</Button></Link>
                            <Link to="/offers/savedoffers"><Button variant="default" className="ml-3 createadv-btn">&nbsp;Saved</Button></Link>
                        </div>
                        <div className="mt-3 active-add-h-text">Published Offers</div>
                        <div class="mt-3 tableFixHead" >
                            <table class="table-striped" >
                                <thead style={{ background: "#eee" }}>
                                    <tr>
                                        <th scope="col" className="th-heading" style={{ width: "20%" }}>Offer Name</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Created At</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>End Date</th>
                                        <th scope="col" className="th-heading" style={{ width: "15%" }}>Reward Points</th>
                                        <th scope="col" className="th-heading" style={{ width: "30%" }}>Url</th>
                                        <th ></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {offers.map((data, i) => (
                                        <tr className={i / 2 === 0 ? "" : "green"}>
                                            <td className="td-text">{data.offerName}</td>
                                            <td className="td-text">{b[i]}</td>
                                            <td className="td-text">{endAdvDate[i]}</td>
                                            <td className="td-text">{data.rewardPoints}</td>
                                            <td className="td-text">{data.url}</td>
                                            <td className="cursor"><Link to={`/offers/viewoffer/${data.id}`} className="poll-link"> <i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* T-shirt Data */}
                                <tbody>
                                    {topicData.map((data, i) => (
                                        <tr className={i % 2 === 1 ? "green" : ""} key={data.id}>
                                            {data.tshirtChoice && (
                                                <td className="td-text">
                                                    {JSON.parse(data.tshirtData).map((tshirt, index) => (
                                                        <div key={index}>
                                                            <p>T-shirt</p>
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                            {data.tshirtChoice && (
                                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.tshirtChoice && (
                                                <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.tshirtChoice && (
                                                <>
                                                    {JSON.parse(data.tshirtData).map((tshirt, index) => (
                                                        <td className="td-text" key={index}>{tshirt.pointsToRedeem}</td>
                                                    ))}
                                                </>
                                            )}
                                            {data.tshirtChoice && (
                                                <td className="td-text">{data.articleUrl}</td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>

                                {/* Mug Data */}
                                <tbody>
                                    {topicData.map((data, i) => (
                                        <tr className={i % 2 === 0 ? "green" : ""} key={data.id}>
                                            {/* Mug Column */}
                                            {data.mugChoice && (
                                                <td className="td-text">
                                                    {JSON.parse(data.mugData).map((mug, index) => (
                                                        <div key={index}>
                                                            <p>Mug</p>
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                            {data.mugChoice && (
                                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.mugChoice && (
                                                <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.mugChoice && (
                                                <>
                                                    {JSON.parse(data.mugData).map((mug, index) => (
                                                        <td className="td-text" key={index}>{mug.pointsToRedeem}</td>
                                                    ))}
                                                </>
                                            )}
                                            {data.mugChoice && (
                                                <td className="td-text">{data.articleUrl}</td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>

                                {/* Keychain Data */}
                                <tbody>
                                    {topicData.map((data, i) => (
                                        <tr className={i % 2 === 1 ? "green" : ""} key={data.id}>
                                            {data.keychainChoice && (
                                                <td className="td-text">
                                                    {JSON.parse(data.keychainData).map((keychain, index) => (
                                                        <div key={index}>
                                                            <p>Keychain</p>
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                            {data.keychainChoice && (
                                                <td className="td-text">{moment(data.createdAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.keychainChoice && (
                                                <td className="td-text">{moment(data.updatedAt).format("DD-MM-YYYY")}</td>
                                            )}
                                            {data.keychainChoice && (
                                                <>
                                                    {JSON.parse(data.keychainData).map((keychain, index) => (
                                                        <td className="td-text" key={index}>{keychain.pointsToRedeem}</td>
                                                    ))}
                                                </>
                                            )}

                                            {data.keychainChoice && (
                                                <td className="td-text">{data.articleUrl}</td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div >
        );
    };
}

