import React, { Component } from "react";
import { Button, Form, Navbar, Nav, Card, ProgressBar, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import moment from "moment-timezone";
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(sessionStorage.getItem('junkStore')),
            orderId: "",
            name: "",
            size: "",
            quantity: "",
            price: "",
            color: "",
            phoneNumber: "",
            orderStatus: "",
            address: "",
            createdOn: "",
            deliveryDate: "",
            paymentMethod: "",
            transactionStatus: "",
            transactionImage: "",
            reason: "",
            isLoading: true
        }
    }
    componentDidMount() {
        this.getOrderById();
    }
    getOrderById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "web"
        }
        // console.log("token", this.state.token)
        var self = this
        axios.get(config.userUrl + `user/getOrderById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    //     console.log("success!!", response);
                    self.setState({
                        orderId: response.data.order[0]?.orderId,
                        name: response.data.order[0]?.name,
                        size: response.data.order[0]?.size,
                        quantity: response.data.order[0]?.quantity,
                        price: response.data.order[0]?.price,
                        color: response.data.order[0]?.color,
                        reason: response.data.order[0]?.reason,
                        address: response.data.order[0]?.address,
                        phoneNumber: response.data.order[0]?.phoneNumber,
                        orderStatus: response.data.order[0]?.orderStatus,
                        createdOn: response.data.order[0]?.createdAt,
                        deliveryDate: response.data.order[0]?.deliveryDate,
                        transactionStatus: response.data.order[0]?.transactionStatus,
                        paymentMethod: response.data.order[0]?.paymentMethod,
                        transactionImage: response.data.order[0]?.transactionImage,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { isLoading } = this.state

        if (isLoading) {
            return <div className="App"></div>;
        }
        return (
            <div>
                <NavBar />
                <Sidebar />
                <div style={{ marginTop: "5.1rem", marginLeft: "275px" }} >
                    <h5 className="font-heading-web">View Order</h5>
                    <Form onSubmit={this.handleSubmit}>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Name</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="name"
                                        placeholder="Name"
                                        value={this.state.name}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>

                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Order Id</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="orderId"
                                        placeholder="Order Id"
                                        value={this.state.orderId}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Size</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="size"
                                        placeholder="Size"
                                        value={this.state.size}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Color</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="color"
                                        placeholder="color"
                                        value={this.state.color}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Quantity</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="quantity"
                                        placeholder="Quantity"
                                        value={this.state.quantity}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Price</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="price"
                                        placeholder="Price"
                                        value={"Rs." + this.state.price}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        <div className="mt-1 poll-date">Address</div>
                        <div className="form-group" style={{ width: "71.5%" }}>
                            <textarea className="web-font form-control" name="addres"
                                placeholder="Address"
                                rows={4}
                                value={this.state.address}
                                style={{ resize: "none" }}
                                readOnly
                                onChange={this.onChange} />
                        </div>
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Phone Number</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="phoneNumber"
                                        placeholder="Phone Number"
                                        value={this.state.phoneNumber}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Order Status</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="orderStatus"
                                        placeholder="Order Status"
                                        value={this.state.orderStatus}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>
                        {/* <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Payment Method</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="paymentMethod"
                                        placeholder="Payment Method"
                                        value={this.state.paymentMethod}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Transaction Status</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="deliveryDate"
                                        placeholder="Transaction Status"
                                        value={this.state.transactionStatus == 1 ? "Pending" : this.state.transactionStatus == 2 ? "Completed" : "Rejected"}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row> */}
                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Created On</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="createdOn"
                                        placeholder="Created On"
                                        value={moment(this.state.createdOn).format("DD-MM-YYYY")}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="poll-date">Delivery Date</div>
                                <div className="form-group">
                                    <input className="web-font form-control" name="deliveryDate"
                                        placeholder="Delivery date"
                                        value={this.state.deliveryDate ? moment(this.state.deliveryDate).format("DD-MM-YYYY") : this.state.deliveryDate}
                                        readOnly
                                        onChange={this.onChange} />
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ width: "74%" }}>
                            <Col md={6}>
                                <div className="poll-date">Transaction Image</div>
                                <img src={this.state.transactionImage} style={{ marginTop: "5px", width: "240px", height: "120px" }} />
                            </Col>
                            <Col md={6}>
                                <div className="mt-1 poll-date">Reason</div>
                                <div className="form-group">
                                    <textarea className="web-font form-control" name="addres"
                                        placeholder="reason"
                                        rows={6}
                                        value={this.state.reason}
                                        style={{ resize: "none" }}
                                        readOnly/>
                                </div></Col>
                        </Row>
                        <Link to="/order/allorders" className="float_right_poll"><Button variant="default" className="cancelreview-btn mt-2">Back</Button></Link>

                    </Form><br /><br />
                </div >
            </div >
        );
    };
}




