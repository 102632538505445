import React, { useParams, useRef, useState, useEffect } from "react";
import { Button, Form, Navbar, Nav, Card, DropdownButton, Col, Row, Dropdown } from "react-bootstrap";
import { Link, Route, useRouteMatch, NavLink, Redirect, Router } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import './ReviewQueue.css';
import orange from '../images/orange-circle.svg';
import { ReviewData } from './ReviewJson';
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default function ReviewQueue() {
    const [selected, setSelected] = useState(0)
    const [token, setToken] = useState(simpleCrypto.decrypt(sessionStorage.getItem('junkStore')))
    const [reviewQueue, setReviewQueue] = useState([])
    const [htmlName, setHtmlName] = useState('');
    const [content, setContent] = useState('');
    const [description, setDescription] = useState('');
    const refText = useRef('myTextarea');
    const [userTopics, setUserTopics] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [topicsLoading, setTopicsLoading] = useState(true)
    const [type, setType] = useState("G")

    let { path, url } = useRouteMatch();
    // console.log(path)

    useEffect(() => {
        getReviewQueue();
        getAllRoleRequests();
    }, []);
    const getAllRoleRequests = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        axios.get(config.userUrl + 'user/getAllRoleRequests', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setUserTopics(response.data.topicDetails)
                    setTopicsLoading(false)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    function parseMarkdown(markdownText) {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, '<h6 class="heading-color"><b>$1</b></h6>')
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />')
        //    console.log("text", newMarkdownText)
        return newMarkdownText
    }
    const getReviewQueue = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }

        axios.get(config.userUrl + 'user/getReviewQueue', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setReviewQueue(response.data.posts)
                    setIsLoading(false)
                    //  console.log("getReviewQueue", response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const onChangeType = (e) => {
        setType(e.target.value)
    }
    const reviewPost = (data, flag) => {
        //  console.log("edit", data)
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "web"
        }
        var payload = {
            "postId": data.id,
            "forUserId": data.forUserId ? data.forUserId : "",
            "type": type,
            "flag": flag,
            "description": description
        }
        //  console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/reviewPost', payload, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status == true) {
                    window.location.href = '/reviewqueue'
                    //   console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    if (!sessionStorage.getItem('junkStore')) {
        return <Redirect to='/' />;
    }
    if (isLoading) {
        return <div></div>
    }
    if (topicsLoading) {
        return <div></div>
    }
    const updatedDate = reviewQueue.map((i, idx) => {
        var a = new Date(i.updatedAt)
        var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return updated;
    })
    const showAccept = () => {
        document.getElementById('accept').style.display = 'flex'
    }
    const showAcceptNone = () => {
        document.getElementById('accept').style.display = 'none'
    }
    const showReject = () => {
        document.getElementById('reject').style.display = 'flex'
    }
    const showRejectNone = () => {
        document.getElementById('reject').style.display = 'none'
    }
    // let result = reviewQueue.filter(item => userTopics.some(data => item.topicName === data.topicName));
    // // console.log("result", result)
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "5.1rem", marginLeft: "275px" }}>

                <Row style={{ width: "100%" }}>
                    <Col md={4}>
                        <div class="scrolling-area-review">
                            <div class="scrolling-element-inside">
                                <div className="inbox">Inbox</div>
                                {reviewQueue.length !== 0 ?
                                    <div>
                                        {reviewQueue.map((data, i) => (
                                            <div>
                                                <Card style={{ border: "none" }}>
                                                    <NavLink to={`${path}/${data.id}`} className="inbox_Card link" activeClassName="_Active" key={i} >
                                                        <Row style={{ width: "100%" }}>
                                                            <Col md={2}>
                                                                <img src={orange} style={{ width: "32px" }} />
                                                            </Col>
                                                            <Col md={7}>
                                                                <div className="messages">{data.title}</div>
                                                                <div className="article-approved">{data.topicName} </div>

                                                            </Col>
                                                            <Col md={3}>
                                                                <div className="time">{updatedDate[i]}</div>
                                                            </Col>
                                                        </Row>
                                                    </NavLink>
                                                </Card>
                                                <hr /></div>
                                        ))}
                                    </div> : <p>No Articles to Review</p>}


                            </div>
                        </div>
                    </Col>
                    <Col md={8}>
                        <Card style={{ height: "85vh" }}>
                            {reviewQueue.map((data, i) => (
                                <Route key={i} path={`${path}/${data.id}`} >
                                    <div style={{ margin: "0 12rem" }}>
                                        <div class="scrolling-area-review-queue">
                                            <div class="scrolling-element-inside">
                                                <center> <img src={config.userUrl + data.image} className="article-title mt-1" style={{ width: "35%", height: "200px" }} /></center>

                                                <p className="article-title article-title-color mt-2">{data.title}</p>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: data.content }}
                                                    readOnly
                                                    className="quill-size form-group richTextField_Review description-radius"
                                                    style={{ resize: "none", wordWrap: "break-word" }}
                                                />
                                                <Card className="mt-3 poll-answer-card-post" style={data.question === "" || data.question === null ? { display: "none" } : { borderRadius: "10px", padding: "5px 10px" }}>
                                                    <center className="question_post">{data.question}</center>
                                                    {data.options?.map((opt, i) => (
                                                        <div className="polloptions-card-post"><div className="poll-text">{opt}</div></div>
                                                    ))}
                                                </Card>
                                            </div>
                                        </div>
                                        {(userTopics.some(item => item.topicName === data.topicName)) ?
                                            <div>
                                                <div className="ml-3 mr-3 mt-2 form-group">
                                                    <select type="text" className='web-font form-control' value={type} onChange={onChangeType}>
                                                        <option value={"G"}>General</option>
                                                        <option value={"P"}>Premium</option>
                                                    </select>
                                                    <textarea
                                                        rows={2}
                                                        name="description"
                                                        value={description}
                                                        placeholder="Write your comments"
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        className="web-font form-control"
                                                        style={{ resize: "none", wordWrap: "break-word", }}
                                                    />
                                                </div>
                                                <div className="float_Right mr-3 mt-1" >

                                                    <div>{description !== "" ?
                                                        <div ><Button onClick={showAccept} className="sendreview-btn ml-4" variant="default">Accept</Button>
                                                            <div id="accept" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Accept?</p>
                                                                        <center><Button variant="default" onClick={() => reviewPost(data, 1)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showAcceptNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div><Button variant="default" onClick={showReject} className="save-btn ml-3">Reject</Button>
                                                            <div id="reject" className="modal_new">
                                                                <form className="modal-content-log">
                                                                    <div className="md-container">
                                                                        <p className="mt-2">Do you really want to Reject?</p>
                                                                        <center><Button variant="default" onClick={() => reviewPost(data, 0)} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                                            <Button variant="default" onClick={showRejectNone} className="deletebutton">No</Button></center>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div> :
                                                        <div><Button variant="default" disabled="true" className="sendreview-btn ml-4">Accept</Button><Button disabled="true" className="save-btn ml-3" variant="default">Reject</Button></div>}
                                                    </div>
                                                </div>
                                            </div> :
                                            <div ><center className="mt-4 error-review">This topic is not of your choice of review. You can view the article.</center><br />
                                                <div className="float_Right mr-3" style={{ marginTop: "28px" }}>
                                                    <Button variant="default" disabled="true" className="sendreview-btn ml-4">Accept</Button><Button disabled="true" className="save-btn ml-3" variant="default">Reject</Button></div>
                                            </div>}
                                    </div>
                                </Route>
                            ))}
                        </Card>
                    </Col>
                </Row>
            </div >
        </div >
    );
}

