import React, { useState, useEffect } from "react";
import { Button, Form, Card, Col, Row } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import NavBar from "../NavBar/NavBar";
import Sidebar from "../Sidebar/Sidebar";
import axios from 'axios';
import { config } from '../Config/Config';
import fileupload from '../images/file-upload-svgrepo-com.svg';
import './WriteArticle.css';
import SimpleCrypto from "simple-crypto-js";

const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

export default function AddTopicpictures() {
    const [allTopics, setAllTopics] = useState([]);
    const [image, setImage] = useState("");
    const [selectedTopic, setSelectedTopic] = useState("");
    const [topicId, setTopicId] = useState("");
    const [title, setTitle] = useState("");
    const [descriptionText, setDescriptionText] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [savedPost, setSavedPost] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [merchandise, setMerchandise] = useState(true);
    const [tshirtChoice, setTshirtChoice] = useState(true);
    const [mugChoice, setMugChoice] = useState(false);
    const [keychainChoice, setKeychainChoice] = useState(false);
    const [type, setType] = useState("Tshirt");
    const [sizeArray, setSizeArray] = useState([]);
    const [id, setId] = useState(null);

    const [tshirtData, setTshirtData] = useState({
        text: "",
        material: "",
        sizeArray: [],
        cost: "",
        shippingCost: "",
        pointsToRedeem: ""
    });

    const [mugData, setMugData] = useState({
        text: "",
        material: "",
        sizeArray: [],
        cost: "",
        shippingCost: "",
        pointsToRedeem: ""
    });

    const [keychainData, setKeychainData] = useState({
        text: "",
        material: "",
        sizeArray: [],
        cost: "",
        shippingCost: "",
        pointsToRedeem: ""
    });

    const token = simpleCrypto.decrypt(sessionStorage.getItem("junkStore"));

    const handleTypeChange = (e) => {
        const newType = e.target.value;
        setType(newType);
    };

    const handleTshirtCheckboxChange = (e) => setTshirtChoice(e.target.checked);
    const handleMugCheckboxChange = (e) => setMugChoice(e.target.checked);
    const handleKeychainCheckboxChange = (e) => setKeychainChoice(e.target.checked);

    const handleSizeChange = (e) => {
        const selectedSize = e.target.value;
        const isChecked = e.target.checked;

        if (type === "Tshirt") {
            setTshirtData((prevData) => ({
                ...prevData,
                sizeArray: isChecked
                    ? [...prevData.sizeArray, selectedSize]
                    : prevData.sizeArray.filter((size) => size !== selectedSize),
            }));
        } else if (type === "Mug") {
            setMugData((prevData) => ({
                ...prevData,
                sizeArray: isChecked
                    ? [...prevData.sizeArray, selectedSize]
                    : prevData.sizeArray.filter((size) => size !== selectedSize),
            }));
        } else if (type === "KeyChain") {
            setKeychainData((prevData) => ({
                ...prevData,
                sizeArray: isChecked
                    ? [...prevData.sizeArray, selectedSize]
                    : prevData.sizeArray.filter((size) => size !== selectedSize),
            }));
        }
    };

    const getData = (type) => {
        if (type === "Tshirt") return tshirtData;
        if (type === "Mug") return mugData;
        if (type === "KeyChain") return keychainData;
        return {};
    };

    const handleDataChange = (type, field, value) => {
        if (type === "Tshirt") {
            setTshirtData({ ...tshirtData, [field]: value });
        } else if (type === "Mug") {
            setMugData({ ...mugData, [field]: value });
        } else if (type === "KeyChain") {
            setKeychainData({ ...keychainData, [field]: value });
        }
    };

    const renderSizeOptions = (type) => {
        if (type === "Tshirt") {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div><input type="checkbox" value="S" checked={tshirtData.sizeArray.includes("S")} onChange={handleSizeChange} /> S</div>
                    <div><input type="checkbox" value="M" checked={tshirtData.sizeArray.includes("M")} onChange={handleSizeChange} /> M</div>
                    <div><input type="checkbox" value="L" checked={tshirtData.sizeArray.includes("L")} onChange={handleSizeChange} /> L</div>
                    <div><input type="checkbox" value="XL" checked={tshirtData.sizeArray.includes("XL")} onChange={handleSizeChange} /> XL</div>
                    <div><input type="checkbox" value="XXL" checked={tshirtData.sizeArray.includes("XXL")} onChange={handleSizeChange} /> XXL</div>
                </div>
            );
        }
        if (type === "Mug") {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div><input type="checkbox" value="S" checked={mugData.sizeArray.includes("S")} onChange={handleSizeChange} /> S</div>
                    <div><input type="checkbox" value="L" checked={mugData.sizeArray.includes("L")} onChange={handleSizeChange} /> L</div>
                </div>
            );
        }
        if (type === "KeyChain") {
            return (
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <div><input type="checkbox" value="Standard" checked={keychainData.sizeArray.includes("Standard")} onChange={handleSizeChange} /> Standard</div>
                </div>
            );
        }
        return null;
    };
    useEffect(() => {
        getAllTopics();
    }, []);
    // Fetch all topics from the API
    const getAllTopics = () => {
        let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            applicationType: "web",
        };
        axios
            .get(config.userUrl + "user/getAllTopics", { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    setAllTopics(response.data.topics);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const addTopicPicture = () => {
        let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            applicationType: "web",
        };

        let payload = {
            id: id ? parseInt(id) : undefined,
            topicId: topicId,
            articleUrl: image,
            descriptionText: descriptionText,
            merchandise: merchandise,
            tshirtChoice: merchandise ? tshirtChoice : tshirtChoice,
            tshirtData: merchandise && tshirtChoice ? [tshirtData] : [],
            mugChoice: merchandise ? mugChoice : mugChoice,
            mugData: merchandise && mugChoice ? [mugData] : [],
            keychainChoice: merchandise ? keychainChoice : keychainChoice,
            keychainData: merchandise && keychainChoice ? [keychainData] : []
        };

        console.log("payload", payload, [tshirtData]);

        axios.post(config.userUrl + "user/addArticleTopicUrl", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    window.location.reload('/addtopicpictures')
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    // Handle topic selection
    const onTopicChange = (e) => {
        setTopicId(parseInt(e.target.value));
        setDescriptionText("");
        setSelectedTopic(e.target.value);

        let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            applicationType: "web",
        };

        var payload = {
            topicId: parseInt(e.target.value),
        };

        axios.post(config.userUrl + "user/getAllArticleTopicUrlByTopicId", payload, { headers: headers })
            .then(function (response) {
                if (response.data.status === true && response.data.getAllArticleTopicUrlByTopicId.length !== 0) {
                    const fetchedData = response.data.getAllArticleTopicUrlByTopicId[0];
                    setImage(fetchedData.articleUrl || "");
                    setId(response.data.getAllArticleTopicUrlByTopicId.length !== 0 ? response.data.getAllArticleTopicUrlByTopicId[0]?.id : null);
                    setDescriptionText(fetchedData.descriptionText || "");
                    setMerchandise(fetchedData.merchandise || false);
                    setTshirtChoice(fetchedData.tshirtChoice || false);
                    setMugChoice(fetchedData.mugChoice || false);
                    setKeychainChoice(fetchedData.keychainChoice || false);

                    // Parse JSON data if present
                    const parsedTshirtData = fetchedData.tshirtData ? JSON.parse(fetchedData.tshirtData) : [];
                    const parsedMugData = fetchedData.mugData ? JSON.parse(fetchedData.mugData) : [];
                    const parsedKeychainData = fetchedData.keychainData ? JSON.parse(fetchedData.keychainData) : [];

                    // Set Tshirt Data
                    if (parsedTshirtData.length > 0) {
                        setTshirtData(parsedTshirtData[0]); // Assuming only one object is returned in the array
                    } else {
                        setTshirtData({
                            text: "",
                            material: "",
                            sizeArray: [],
                            cost: "",
                            shippingCost: "",
                            pointsToRedeem: ""
                        });
                    }

                    // Set Mug Data
                    if (parsedMugData.length > 0) {
                        setMugData(parsedMugData[0]); // Assuming only one object is returned in the array
                    } else {
                        setMugData({
                            text: "",
                            material: "",
                            sizeArray: [],
                            cost: "",
                            shippingCost: "",
                            pointsToRedeem: ""
                        });
                    }

                    // Set KeyChain Data
                    if (parsedKeychainData.length > 0) {
                        setKeychainData(parsedKeychainData[0]); // Assuming only one object is returned in the array
                    } else {
                        setKeychainData({
                            text: "",
                            material: "",
                            sizeArray: [],
                            cost: "",
                            shippingCost: "",
                            pointsToRedeem: ""
                        });
                    }

                    // Set the form fields based on the current type
                    let currentType = type;
                    if (currentType === "Tshirt") {
                        setTshirtData(parsedTshirtData[0] || {});
                    } else if (currentType === "Mug") {
                        setMugData(parsedMugData[0] || {});
                    } else if (currentType === "KeyChain") {
                        setKeychainData(parsedKeychainData[0] || {});
                    }

                } else {
                    // Reset state when no data is found for the selected topic
                    setImage("");
                    setType('Tshirt');
                    setSizeArray([]);
                    setTshirtChoice(true);
                    setMugChoice(false);
                    setKeychainChoice(false);
                    setTshirtData({
                        text: "",
                        material: "",
                        sizeArray: [],
                        cost: "",
                        shippingCost: "",
                        pointsToRedeem: ""
                    });
                    setMugData({
                        text: "",
                        material: "",
                        sizeArray: [],
                        cost: "",
                        shippingCost: "",
                        pointsToRedeem: ""
                    });
                    setKeychainData({
                        text: "",
                        material: "",
                        sizeArray: [],
                        cost: "",
                        shippingCost: "",
                        pointsToRedeem: ""
                    });
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const submitPost = () => {
        document.getElementById('post').style.display = 'flex'
    }
    const showNone = () => {
        document.getElementById('post').style.display = 'none'
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }
    const handleImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const res = await uploadImage(file);
            if (res) {
                setImage(res);
                console.log("resbanner", res)
            }
        } else {
            // console.warn('No file selected');
        }
    }
    // if (redirect) {
    //     return <Redirect to="/addtopicpictures" />;
    // }
    const isTshirtDisabled = !tshirtChoice;
    const isMugDisabled = !mugChoice;
    const isKeychainDisabled = !keychainChoice;
    return (
        <div>
            <NavBar />
            <Sidebar />
            <div style={{ marginTop: "4.8rem", marginLeft: "260px" }}>

                <Form style={{ marginLeft: "1.2rem", width: "75%" }}>
                    <Card style={{ width: "85%", padding: "10px", border: "none" }}>
                        <h5 className="mt-2 font-heading-web">Add/Edit Topic Picture</h5><br />
                        <Row style={{ width: "100%" }} >
                            <Col md={6}>
                                <div className="mt-2 form-group">
                                    <select name="topicId" value={selectedTopic} onChange={onTopicChange} className="web-font form-control text-box-radius">
                                        <option value="">Select Topic</option>
                                        {allTopics.map((topic) => (
                                            <option key={topic.id} value={topic.id}>{topic.topicName}</option>
                                        ))}
                                    </select>
                                </div>
                                <label className="poll-date">Description<span className="red">*</span></label>
                                <div className="form-group">
                                    <textarea
                                        type="text"
                                        className="web-font form-control"
                                        aria-describedby="text"
                                        rows="5"
                                        value={descriptionText}
                                        name="descriptionText"
                                        style={{ resize: "none" }}
                                        placeholder="Description"
                                        onChange={e => setDescriptionText(e.target.value)} />
                                </div>
                                <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Edit/Upload Image</label><br />
                                <div style={{ height: "220px", textAlign: "center", objectFit: "contain", border: "1px solid black", borderRadius: "10px" }}>
                                    <label htmlFor="upload-button-banner">
                                        {image !== "" ? (
                                            <img src={image} alt="Uploaded" style={{ marginTop: "4px", marginBottom: "4px", width: "140px", height: "200px" }} />
                                        ) : (
                                            <>
                                                <img src={fileupload} alt="Upload" style={{ marginTop: "6rem", cursor: "pointer", width: "29px" }} />
                                            </>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        id="upload-button-banner"
                                        name="image"
                                        onChange={handleImage}
                                    />
                                </div>
                                <label className="mt-3 poll-date">
                                    Do you want to have merchandise?
                                    <input
                                        type="radio"
                                        className="ml-4"
                                        name="merchandise"
                                        value={true}
                                        checked={merchandise}
                                        onChange={() => setMerchandise(true)}
                                    />
                                    Yes
                                    <input
                                        type="radio"
                                        className="ml-2"
                                        name="merchandise"
                                        value={false}
                                        checked={!merchandise}
                                        onChange={() => setMerchandise(false)}
                                    />
                                    No
                                </label>
                            </Col>
                            <Col md={6}>
                                <div style={merchandise ? {} : { display: "none" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>
                                            <input
                                                type="checkbox"
                                                value="Tshirt"
                                                checked={tshirtChoice}
                                                onChange={handleTshirtCheckboxChange}
                                            />
                                            <label className="form-check-label">Tshirt</label>
                                        </div>
                                        <div>
                                            <input
                                                type="checkbox"
                                                value="Mug"
                                                checked={mugChoice}
                                                onChange={handleMugCheckboxChange}
                                            />
                                            <label className="form-check-label">Mug</label>
                                        </div>
                                        <div>
                                            <input
                                                type="checkbox"
                                                value="Keychain"
                                                checked={keychainChoice}
                                                onChange={handleKeychainCheckboxChange}
                                            />
                                            <label className="form-check-label">Keychain</label>
                                        </div>
                                    </div>

                                    {/* <label className="mt-2 poll-date">Type</label>
                                    <select className="form-control web-font" value={type} onChange={handleTypeChange}>
                                        <option value="Tshirt">Tshirt</option>
                                        <option value="Mug">Mug</option>
                                        <option value="KeyChain">KeyChain</option>
                                    </select> */}
                                    <label className="mt-2 poll-date">Type</label>
                                    <select
                                        className="form-control web-font"
                                        value={type}
                                        onChange={handleTypeChange}
                                    >
                                        <option value="Tshirt" disabled={isTshirtDisabled}>Tshirt</option>
                                        <option value="Mug" disabled={isMugDisabled}>Mug</option>
                                        <option value="KeyChain" disabled={isKeychainDisabled}>KeyChain</option>
                                    </select>

                                    <label className="mt-2 poll-date">{type} Description</label>
                                    <textarea
                                        className="web-font form-control"
                                        rows="3"
                                        value={getData(type).text}
                                        onChange={(e) => handleDataChange(type, 'text', e.target.value)}
                                    />

                                    <label className="mt-2 poll-date">Material</label>
                                    <input
                                        type="text"
                                        className="web-font form-control"
                                        value={getData(type).material}
                                        onChange={(e) => handleDataChange(type, 'material', e.target.value)}
                                    />

                                    <label className="mt-2 poll-date">Size</label>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {renderSizeOptions(type)}
                                    </div>

                                    <label className="mt-2 poll-date">Amount (in Rs)</label>
                                    <input
                                        type="text"
                                        className="web-font form-control"
                                        value={getData(type).cost}
                                        onChange={(e) => handleDataChange(type, 'cost', e.target.value)}
                                    />

                                    <label className="mt-2 poll-date">Shipping Cost (in Rs)</label>
                                    <input
                                        type="text"
                                        className="web-font form-control"
                                        value={getData(type).shippingCost}
                                        onChange={(e) => handleDataChange(type, 'shippingCost', e.target.value)}
                                    />

                                    <label className="mt-2 poll-date">Points To Redeem</label>
                                    <input
                                        type="text"
                                        className="web-font form-control"
                                        value={getData(type).pointsToRedeem}
                                        onChange={(e) => handleDataChange(type, 'pointsToRedeem', e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {
                            (topicId !== "" && image !== "" && descriptionText !== "") ? (
                                <div>
                                    <Button
                                        variant="default"
                                        onClick={submitPost}
                                        className="sendreview-btn mt-2 ml-4"
                                        disabled={merchandise && (
                                            (tshirtChoice && (
                                                !tshirtData.text ||
                                                !tshirtData.material ||
                                                tshirtData.sizeArray.length === 0 ||
                                                !tshirtData.cost ||
                                                !tshirtData.shippingCost ||
                                                !tshirtData.pointsToRedeem
                                            )) ||
                                            (mugChoice && (
                                                !mugData.text ||
                                                !mugData.material ||
                                                mugData.sizeArray.length === 0 ||
                                                !mugData.cost ||
                                                !mugData.shippingCost ||
                                                !mugData.pointsToRedeem
                                            )) ||
                                            (keychainChoice && (
                                                !keychainData.text ||
                                                !keychainData.material ||
                                                keychainData.sizeArray.length === 0 ||
                                                !keychainData.cost ||
                                                !keychainData.shippingCost ||
                                                !keychainData.pointsToRedeem
                                            ))
                                        )}
                                    >
                                        Submit
                                    </Button>

                                    <div id="post" className="modal_new">
                                        <form className="modal-content-log">
                                            <div className="md-container">
                                                <p className="mt-2">Do you want to submit?</p>
                                                <center>
                                                    <Button variant="default" onClick={addTopicPicture} className="cancelbutton">Yes</Button>&nbsp;&nbsp;
                                                    <Button variant="default" onClick={showNone} className="deletebutton">No</Button>
                                                </center>
                                            </div>
                                        </form>
                                    </div>

                                    <Link to="/dashboard">
                                        <Button variant="default" className="cancelreview-btn mt-2 ml-3">Back</Button>
                                    </Link>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        variant="default"
                                        disabled={true}
                                        className="sendreview-btn mt-2 ml-3"
                                    >
                                        Submit
                                    </Button>
                                    <Link to="/dashboard">
                                        <Button variant="default" className="cancelreview-btn mt-2 ml-3">Back</Button>
                                    </Link>
                                </div>
                            )
                        }
                    </Card>
                </Form>
            </div>
        </div >
    );
}